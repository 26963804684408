<template>
  <div class="company-working-hour">
    <div class="left">
      <div class="calendar__filter">
        <div class="filter__item filter__period">
          <dayPicker 
            class="picker day-picker"
            :key="'filter-period'"
            :preset="calendar.start + ''"
            :presetIcon="['append', false, false]"
            :title="'날짜선택'"
            :fieldHeight="33"
            :clickPick="true"
            :border="true"
            :type="calendar.types[calendar.type] == 'month' ? 'month' : 'date'"
            @picked="getDate"
          ></dayPicker>
          <div class="calendar-type" :class="{ 'mac': is_mac }">
            <v-btn-toggle 
              v-model="calendar.type" 
              mandatory tile
            >
              <v-btn class="day">일</v-btn>
              <v-btn class="week">주</v-btn>
              <v-btn class="month">월</v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <div class="filter__item filter__date">
          <v-btn 
            class="prev"
            fab small text
            @click="setCalPrev"
          >
            <v-icon size="32">mdi-chevron-left</v-icon>
          </v-btn>
          <h3 class="date">
            {{ calendar.types[calendar.type] == 'month' ? setDate2Month(calendar.start): calendar.start }}
          </h3>
          <v-btn 
            class="next"
            fab small text
            @click="setCalNext"
          >
            <v-icon size="32">mdi-chevron-right</v-icon>
          </v-btn>
        </div>

        <div class="filter__item filter__machine">

          <!-- 스케줄 등록 / 수정 -->
          <v-dialog 
            content-class="add-schedule__popup dialog--black-theme" 
            v-model="schedule.open"
            persistent dark
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                v-bind="attrs"
                v-on="on"
                class="add-schedule filter-fn-btn"
                :class="{ 'mac': is_mac }"
                @click="showScheduleForm"
                dark tile depressed
              >스케줄 등록</v-btn>
            </template>
            <v-card class="inner">
              <p class="dialog-title">
                스케줄
                <template v-if="schedule.mode == 'create'">등록</template>
                <template v-else-if="schedule.mode == 'modify'">수정</template>
              </p>
              <div class="add-schedule__cont dialog__content">
                <!-- <label v-if="schedule.mode == 'create' " class="add-sche__label">
                  <span class="label-text">기 계 명</span>
                  <select name="schedule-mid" class="schedule-mid" v-model="schedule.form.mkey">
                    <option value="" default selected disabled>기계명</option>
                    <option
                      class="schedule-mid__i"
                      v-for="m in mid.data"
                      :key="m.id"
                      :value="m.id" 
                    >{{ m.name }}</option>
                  </select>
                </label> -->
                <label class="dialog__form-label">
                  <span class="label-text">조 업 명</span>
                  <v-text-field 
                    class="label-field name-field rounded-0" 
                    v-model="schedule.form.name"
                    height="35"
                    label="조업명"
                    hide-details solo flat dense light
                  ></v-text-field>
                </label>
                <label class="dialog__form-label">
                  <span class="label-text">등 록 일</span>
                  <dayPicker
                    class="label-field" 
                    :key="'schedule-added-date'" 
                    :preset="schedule.date"
                    :fieldHeight="35"
                    :clickPick="true"
                    :offsetX="true"
                    :offsetY="false"
                    @picked="setScheduleDate"
                  ></dayPicker>
                </label>
                <label class="dialog__form-label">
                  <span class="label-text">시 작 시 간</span>
                  <v-menu 
                    content-class="sche-menu label-field--prepend schedule-start-time" 
                    v-model="schedule.start.open"
                    :close-on-content-click="false"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        class="label-field label-field--prepend rounded-0"
                        v-bind="attrs"
                        v-on="on"
                        v-model="schedule.start.time"
                        label="시간 선택"
                        height="35"
                        hide-details readonly solo flat dense light
                      >
                        <template v-slot:prepend>
                          <v-icon size="18">$miniClock</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-card class="menu__inner">
                      <v-time-picker 
                        ref="sche-timepicker-start"
                        v-model="schedule.start.time"
                        :max="schedule.end.time"
                        color="#556b84"
                        format="24hr"
                        @change="(v) => closeTimePicker(v, 'start', )"
                      ></v-time-picker>
                    </v-card>
                  </v-menu>
                </label>
                <label class="dialog__form-label">
                  <span class="label-text">종 료 시 간</span>
                  <v-menu 
                    content-class="sche-menu schedule-end-time" 
                    v-model="schedule.end.open"
                    :close-on-content-click="false"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        class="label-field label-field--prepend rounded-0"
                        v-bind="attrs"
                        v-on="on"
                        v-model="schedule.end.time"
                        height="35"
                        label="시간 선택"
                        hide-details readonly solo flat dense light
                      >
                        <template v-slot:prepend>
                          <v-icon size="18">$miniClock</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-card class="menu__inner">
                      <v-time-picker 
                        ref="sche-timepicker-end"
                        v-model="schedule.end.time"
                        :min="schedule.start.time"
                        color="#556b84"
                        format="24hr"
                        @change="(v) => closeTimePicker(v, 'end')"
                      ></v-time-picker>
                    </v-card>
                  </v-menu>
                </label>
              </div>
              <v-card-actions 
                class="dialog__actions"
                :class="{ 'mac': is_mac, }"
              >
                <v-btn 
                  class="action-btn add-schedule-cancle"
                  @click="addSchedule"
                  depressed light
                >확인</v-btn>
                <v-btn 
                  class="action-btn add-schedule-complete"
                  @click="cancleScheduleAdd"
                  depressed light
                >취소</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- 스케줄 등록 / 수정 끝 -->
        </div>
      </div>

      <div class="calendar-area">
        <v-sheet :height="calendar.height">
        <!-- <v-sheet :height="calendar.type == 2 ? 800 : 'auto'"> -->
          <v-calendar 
            ref="calendar"
            class="calendar"
            :class="{ 'calendar--day': calendar.type == 0, }"
            v-model="calendar.start"
            @change="setRange"
            :start="calendar.start"
            :type="calendar.types[calendar.type]"
            :weekday-format="getDayNames"
            :show-month-on-first="false"
            :events="events"
            :event-height="25"
            :event-margin-bottom="5"
            :event-overlap-mode="calendar.types[calendar.type] == 'day' ? 'column' : 'stack'"
            @click:event="showEventDetail"
            @click:more="showEventMore"
            event-more-text="{0} 더보기 &blacktriangledown;"
          >
          </v-calendar>
        </v-sheet>
        <v-menu
          content-class="calendar__tooltip elevation-0 rounded-0"
          v-model="tooltip.open"
          :activator="tooltip.element"
          :close-on-content-click="false"
          :close-on-click="false"
          right offset-x dark
        >
          <v-card class="inner elevation-0 rounded-0">
            <v-card-actions class="tooltip__actions">
              <v-spacer></v-spacer>
              <v-btn class="tooltip__delete" fab text x-small @click="openDeleteConfirm">
                <v-icon size="14">$deleteIcon</v-icon>
              </v-btn>
              <v-btn class="tooltip__modify" fab text x-small @click="openModifySchedule">
                <v-icon size="14">$modify</v-icon>
              </v-btn>
              <v-btn class="tooltip__close" fab text x-small @click="closeEventDetail">
                <v-icon size="14">$close</v-icon>
              </v-btn>
            </v-card-actions>
            <div class="tooltip__content">
              <p class="content-title tooltip-name">{{ tooltip.data.name }}</p><br>
              <!-- <p class="tooltip-mid">{{ tooltip.data.category }}</p> -->
              <p class="tooltip-time">
                <template v-if="tooltip.all_day">
                  종일
                </template>
                <template v-else>
                  <label class="tooltip-label">
                    <span class="label-text">시 작</span>
                    {{ tooltip.data.start.replace(' ', ' / ') }}
                  </label>
                  <v-spacer></v-spacer>
                  <label class="tooltip-label">
                    <span class="label-text">종 료</span>
                    {{ tooltip.data.end.replace(' ', ' / ') }}
                  </label>
                </template>
              </p>
            </div>
          </v-card>
        </v-menu>
        <div class="bottom__panel unoperatin-hour">
          <span class="panel-text"><strong>비조업 시간</strong> {{ timeFormatter(down_time) }}</span>
        </div>

        <!-- 삭제 확인 팝업 -->
        <v-dialog 
          content-class="delete-confirm"
          v-model="tooltip.confirm"
          persistent dark
        >
          <v-card class="inner">
            <div class="dialog__content">
              <div class="dialog__confirm-text">
                <strong>{{confirmMent(tooltip.data.start)}}  {{ tooltip.data.name }}</strong>(을)를<br>
                정말 삭제하시겠습니까?
              </div>
            </div>
            <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac }">
              <v-btn 
                class="action-btn cancle-delete-schedule"
                @click="tooltip.confirm = false"
                light
              >취소</v-btn>
              <v-btn 
                class="action-btn delete-schedule"
                @click="deleteEvent"
                light
              >삭제</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="right">
      <div class="process-board">
        <ul class="process-list">
          <li 
            class="process"
            v-for="p, i in process"
            :key="'process' + i"
          >
            <p class="process-title company-name">{{ p.name }}</p>
            <div class="process-content">
              <p class="process-data process-name">
                <span class="process-data-name">공정명</span>
                <span class="process-data-content">{{ p.product_name }}</span>
              </p>
              <p class="process-data process-quantity">
                <span class="process-data-name">수량</span>
                <span class="process-data-content">{{ p.quantity }}</span>
              </p>
              <p class="process-data process-due">
                <span class="process-data-name">완료목표</span>
                <span class="process-data-content">{{ p.due_date }}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import dayPicker from '../components/dayPicker.vue';
import moment from 'moment';

export default {
  components: {
    dayPicker, moment,
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      calendar: {
        height: 800,
        type: 0,
        start: moment().format('YYYY-MM-DD'),
        types: ['day', 'week', 'month'],
        colors: ['#d2c3c9', '#bcd4df', '#bdc3c4', '#c9beb8', '#b3c5ad', '#9c8596', '#8caab4',],
        date: {
          start: '',
          end: '',
        }
      },
      schedule: {
        open: false,
        mode: 'create',
        name: '',
        date: moment().format('YYYY-MM-DD'),
        start: {
          open: false,
          time: '',
        },
        end: {
          open: false,
          time: '',
        },
        form: {
          start: '',
          end: '',
          name:'',
        }
      },
      tooltip: {
        open: false,
        element: undefined,
        all_day: undefined,
        data: {
          name: '',
          start: '',
          end: '',
          color: '',
        },
        confirm: false,
      },
      events: [
        {
          'name': '오전1',
          start: '2021-10-05 08:00:00',
          end: '2021-10-05 12:32:24',
          color: '#d2c3c9',
        },
      ],
      process: [
     
      ],
      range_diff:Number,
      down_time: Number,
      sum_diff: Number,
    };
  },
  methods: {
    orderData(){
      this.$http.APS.get('/order/').then(r=>{
        if(r.data.length!=0){
          for(let i = 0 ; i<r.data.length; i++){
            r.data[i].due_date=moment(r.data[i].due_date).format('YYYY-MM-DD');
          }
          this.process=r.data;
        }
      })
    },
    getContentHeight() {
      const hei = window.innerHeight;
      const filter = document.querySelector('.calendar__filter');
      const except = {
        header: 70,
        filter: parseInt(getComputedStyle(filter).height),
        padding: 100,
      };
      this.calendar.height = hei - except.header - except.filter - except.padding - 5;
    },
    getDate(val) {
      this.calendar.start = val;
    },
    setCalNext() {
      this.$refs.calendar.next();
    },
    setCalPrev() {
      this.$refs.calendar.prev();
    },
    setDate2Month(val) {
      return String(val).substr(0, 7);
    },
    showScheduleForm(){
      this.schedule.mode='create';
      this.cardshow=true;
    },
    setScheduleDate(val){
      this.schedule.date = val;
    },
    closeTimePicker(val, type) {
      this.schedule[type].open = false;
      this.$refs['sche-timepicker-' + type].selectingHour = true;
    },
    getList(start,end){
      const cal = this.calendar;
      this.$http.APS.get('/work/'+start +'/'+end).then(r=>{
        this.sum_diff = 0;
        for(let i = 0; i < r.data.length; i++){
          var index =  i % cal.colors.length;
          r.data[i].color = cal.colors[index];
          r.data[i].start = this.dateFormatter(r.data[i].start);
          r.data[i].end = this.dateFormatter(r.data[i].end); 
          this.sum_diff += r.data[i].diff;
        }
        this.down_time = this.range_diff - this.sum_diff;
        this.events = r.data;
      })
    },
    dateFormatter(v){
      v = v.substr(0,19).replace('T',' ');
      return v;
    },
    confirmMent(v){
      v = v.substr(0,10);
      return v;
    },
    async addSchedule(){
      const sche = this.schedule;
      const cal = this.calendar;

      sche.form.start = sche.date + ' ' + sche.start.time;
      sche.form.end = sche.date + ' ' + sche.end.time;
      sche.form.id = this.tooltip.data.id;
      if(sche.mode == 'create'){
        await this.$http.APS.post('/work/in', sche.form);
        await this.getList(cal.date.start, cal.date.end);
      }else if(sche.mode == 'modify'){
        await this.$http.APS.patch('/work/', sche.form);
        await this.getList(cal.date.start, cal.date.end);
        this.closeEventDetail();
      }
      this.resetScheduleForm();
      sche.open = false;
    },
    cancleScheduleAdd(){
      this.resetScheduleForm();
      this.schedule.open = false;
    },
    resetScheduleForm(){
      const sche = this.schedule;
      sche.mkey = '';
      sche.name = '';
      sche.date = this.today;
      sche.end.time = '';
      sche.start.time = '';
    },
    openModifySchedule() {
      const sche = this.schedule;
      const tooltip = this.tooltip;
      sche.open = true;
      sche.mode = 'modify';
      sche.form.mkey = tooltip.data.mid;
      sche.form.name = tooltip.data.name.split('(')[0];
      sche.date = tooltip.data.start.substr(0, 10);
      sche.start.time = tooltip.data.start.substr(11);
      sche.end.time = tooltip.data.end.substr(11);
    },
    setRange({start,end}){
      const cal = this.calendar;
      cal.date.start = start.date;
      cal.date.end = end.date;
      var end_date = moment(end.date).add(1,'d').format('YYYY-MM-DD');
      var down_time=moment(end_date).diff(start.date,'seconds');
      this.range_diff = down_time;
      // var mkey;
      // mkey = this.mid.selected.length > 1 ?  this.mid.selected.split('.')[1] :  this.mid.selected ;
      this.getList(cal.date.start, cal.date.end);
    },
    getDayNames(d) {
      const day = ['일', '월', '화', '수', '목', '금', '토', ];
      return day[d.weekday];
    },
    timeFormatter(v){
      var hour = parseInt(v/3600);
      var min = parseInt((v%3600)/60);
      var seconds = parseInt((v%3600)%60/60);
      return hour + "시간 " + min + "분 " + seconds + "초";
    },
    showEventDetail({nativeEvent, event}) {
      const tooltip = this.tooltip;
      tooltip.data = event;
      const open = () => {
        tooltip.data = event;
        tooltip.element = nativeEvent.target;
        requestAnimationFrame( () => requestAnimationFrame( () => tooltip.open = true));
      }
      event.start.indexOf(':') == -1 ? tooltip.all_day = true : tooltip.all_day = false;
      if( tooltip.open ) {
        tooltip.open = false;
        requestAnimationFrame( () => requestAnimationFrame( () => open() ));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    closeEventDetail() {
      const tooltip = this.tooltip;
      tooltip.open = false;
      tooltip.element = undefined;
    },
    showEventMore(val) {
      const cal = this.calendar;
      cal.type = 'day';
      cal.start = val.date;
    },
    openDeleteConfirm() {
      const tooltip = this.tooltip;
      tooltip.confirm = true;
    },
    async deleteEvent() {
      const tooltip = this.tooltip;
      const cal = this.calendar;
      await this.$http.APS.delete('/work/' + tooltip.data.id);
      await this.getList(cal.date.start, cal.date.end);
      tooltip.confirm = false;
      this.closeEventDetail();
    },
  },
  computed: {
    is_mac() {
      return this.$is_mac;
    }
  },
  watch: {
    'calendar.start': function(v){
      this.tooltip.open = false;
    },
    "calendar.type": function(newV, oldV) {
      this.tooltip.open = false;
    },
  },
  mounted() {
    this.getContentHeight();
    this.orderData();
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/css/global.scss';

  .company-working-hour {
    @extend .common-calendar;

    max-height: 100% !important;
    flex-flow: row nowrap !important;
    justify-content: stretch;

    .left {
      @extend .flex_column;

      justify-content: stretch;
      flex: 1 1 0;

      > div {
        width: 100%;
      }
      .calendar__filter {
        @extend .common-filter;

        @include relative-web1 {
          flex-wrap: wrap;
          padding: 32px 45px 24px;

          .filter__date {
            order: 2;
            justify-content: center;
            width: 100%;
            margin-top: 24px;
            position: static;
            transform: unset;
            padding-top: 0;
          }
          .filter__period {
            order: 0;
          }
          .filter__machine {
            order: 1;
          }
        }
      }
      .calendar-area {
        padding-bottom: 100px;
        position: relative;

        .bottom__panel {
          @extend .flex_row;

          justify-content: center;
          align-items: flex-start;
          width: 100%;
          height: 100px;
          padding-top: 20px;
          font-size: 24px;
          font-weight: 400;
          position: absolute;
          left: 0;
          bottom: 0;

          strong {
            font-weight: 600;
          }
        }
      }
    }
    .right {
      flex: 0 0 370px;
      height: calc(100vh - 70px);
      overflow-y: hidden;
      background-color: #e6ebee;
    }
    .process-board {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 24px 40px;

      .process-list {
        width: 100%;
        height: auto;

        .process {
          width: 100%;
          border-bottom: 1px solid black;
          padding: 28px 0;

          .process-title {
            font-size: 24px;
            font-family: $ptd !important;
            font-weight: 600;
            margin-bottom: 18px;
          }
          .process-content {

            .process-data {
              @extend .flex_row;

              justify-content: stretch;
              align-items: flex-start;
              width: 100%;

              span {
                font-family: $ptd !important;
                font-size: 16px;
                line-height: 1.5;
              }
              &-name {
                flex: 0 0 90px;
                font-weight: 500;
              }
              &-content {
                flex: 1 1 auto;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .calendar__tooltip {
    @extend .tooltip-actions--black-theme;
  }
  .delete-confirm {
    @extend .dialog--black-theme;
  }

</style>